/* 推送区县 */
<template>
  <v-dialog
    v-model="userSelect"
    width="800px"
    style="overflow-y:none"
  >
    <v-card style="height:100%">
      <v-toolbar
        color="info"
        dark
      >
        <v-toolbar-title>{{ orgName }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          color="#0D47A1"
          @click="submove"
        >
          提交
        </v-btn>
      </v-toolbar>
      <v-container>
        <div
          v-if="desserts.length"
          class="mt-5 mb-5"
        >
          <b class="green--text text--darken-4">
            项目已推送区县：
          </b>
          <span
            v-for="(item,inx) in desserts"
            :key="inx"
          >
            {{ item.data.park }},
          </span>
        </div>
        <v-row class="tscNum">
          <v-col
            cols="6"
            class="pb-0"
          >
            <a-tree-select
              v-model="parkCode"
              tree-checkable
              dropdown-class-name="downname"
              multiple
              tree-check-strictly
              style="width:100%"
              :dropdown-style="{ maxHeight: '58vh' }"
              show-search
              :tree-data="userGroupTree"
              tree-default-expand-all
              allow-clear
              placeholder="区县（点击展开）"
              :replace-fields="replaceFields"
              tree-node-filter-prop="title"
              @change="changeOrg"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
  import _ from 'lodash'
  export default {
    components: {},
    props: {
      formId: {
        type: String,
        default: null,
      },
      // 父级推送id
      parentId: {
        type: String,
        default: null,
      },
      // 父级项目id
      allparentId: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        round: '',
        opinions: '',
        parkCode: [],
        // 推送列表（默认外商推送列表）
        childformId: '5afc5ec4-b2a0-49dc-b042-75d0079b396c',
        // 项目动态（推送子表单，暂时存在，已不使用）
        dynamicformId: 'cf45fb6b-8c8f-4e1e-b9f8-1b2b774fea2b',
        // 外商
        wsFormId: 'bce0f632-42a8-4cf7-a30c-e5c6f00c6e62',
        // 推介
        tjFormId: 'defdcd74-fe79-4906-b3aa-dbdb0ce55afe',
        orgList: [],
        replaceFields: { title: 'name', value: 'id' },
        userGroupTree: [],
        userSelect: false,
        desserts: [],
        parentOrgId: '',
        allGroupTree: [],
        detailList: {},
        // 会商意见表(共用)
        searchParame: {
          formId: 'd3ced02a-aee1-48dc-9a54-93323876d62c',
          _from: 'public',
          page: 0,
        },
        orgValue: '',
        orgName: '项目推送',
        isadd: false,
        // 编辑时会商信息
        editData: null,
        edit: false,
        isHshow: false,
        isFirst: false,
      }
    },
    async mounted () {
      this.init()
    },
    methods: {
      async open (type, editData) {
        // 获取已推送(防止再次编辑时树状框中显示错误，已推送的不再显示，搭配市级树状信息)
        await this.getchild()
        // 市级树状信息
        await this.getUserGroupTree()
        this.userSelect = true
      },
      async init () {
        // 父级项目信息
        await this.getDetail()
        // 个人信息
        await this.getMeInfo()
        // 获取已推送
        await this.getchild()
        // 市级树状信息
        await this.getUserGroupTree()
      },
      async getMeInfo () {
        const res = await this.$axios.get('/me')
        console.log('res', res)
      },
      async getDetail () {
        const resp = await this.$axios.get(
          '/reports/' + this.allparentId + '/detail',
        )
        this.detailList = resp.data.data
      },
      async getchild () {
        const paramsData = {
          formId: this.childformId,
          page: 0,
          size: 999,
          parentID: this.parentId,
        }
        const resp = await this.$axios.get('/reports/query', {
          params: paramsData,
        })
        this.desserts = resp.data.data.content
        console.log('this.desserts', this.desserts);
      },
      async getUserGroupTree () {
        const res = await this.$axios.get('/dictionaries/roles')
        const resData = res.data.data
        this.allGroupTree = resData
        resData.map((val) => {
          // 市区区为父级
          if (val.id === localStorage.orgId) {
            console.log('val', val);

            this.parentOrgId = val.id
            const userGroupTree = this.treeData(resData, val.id)
            userGroupTree.sort((a, b) => {
              return a.weight - b.weight
            })
            this.userGroupTree = userGroupTree
          }
        })
      },

      treeData (sourceData, id) {
        const cloneData = JSON.parse(JSON.stringify(sourceData))
        return cloneData.filter(father => {
          // 如果推送了直接禁用  不能再推送相同的
          if (this.desserts.length) {
            this.desserts.map(vam => {
              if (father.id === vam.data.parkId) {
                console.log('father', father);
                this.$set(father, 'disabled', true)
              }
            })
          }
          const branchArr = cloneData.filter(child => father.id === child.parentId)
          branchArr.length > 0 ? father.children = branchArr : father.children = []
          return father.parentId + '' === id + ''
        })
      },
      async changeOrg (value) {
        this.orgList = value
      },

      submove:
        _.throttle(async function () {
          if (!this.orgList) {
            this.selPark('请选择推送区县！')
          } else {
            // 先更新再推送
            const formData = {}
            const labels = []
            const values = []
            this.parkCode.map((val) => {
              labels.push(val.label)
              values.push(val.value)
            })
            formData.park1 = labels.toString()
            formData.parkId1 = values.toString()
            await this.pushmove()
          }
        }, 3000, { trailing: false }),
      selPark (text) {
        return this.$swal({
          title: text,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: '知道了',
        })
      },
      async pushmove () {
        const filterFormData = {}
        filterFormData.stageStatus = '待处理'
        filterFormData.industry = this.detailList.data.industry
        filterFormData.fillingUser = this.detailList.data.fillingUser
        filterFormData.parentName = this.detailList.name
        filterFormData.parentID = this.parentId
        filterFormData.allparentID = this.allparentId
        filterFormData.parentFormId = this.detailList.formId
        var code = 200
        // 没选择市区不推送
        if (this.orgList.length) {
          for (let inx = 0; inx < this.orgList.length; inx++) {
            filterFormData.name =
              this.detailList.name + '--' + this.orgList[inx].label
            filterFormData.park = this.orgList[inx].label
            filterFormData.parkId = this.orgList[inx].value
            // 只要市区id和名称
            this.allGroupTree.map((vam) => {
              // 市区的
              if (
                vam.id === this.orgList[inx].value &&
                vam.parentId === this.parentOrgId
              ) {
                filterFormData.parkIds = this.orgList[inx].value
                filterFormData.parks = this.orgList[inx].label
                // 园区的
              } else if (
                vam.id === this.orgList[inx].value &&
                vam.parentId !== this.parentOrgId
              ) {
                // filterFormData.parkIds = vam.parentId + ',' + this.orgList[inx].value
                filterFormData.parkIds = vam.parentId
                this.allGroupTree.map((vas) => {
                  if (vam.parentId === vas.id) {
                    filterFormData.parks = vas.name
                  }
                })
              }
            })
            // 创建推送表
            const resp = await this.$axios.post(
              `/forms/${this.childformId}/reports3`,
              {
                data: filterFormData,
                status: 0,
                weight: 10000,
                parentId: this.parentId,
              },
            )
            code = resp.data.code
            console.log('resp', resp, resp.data.code)
            // 给市区授权这个推送
            this.putGroup(resp.data.data.id, this.orgList[inx].value)
            // 创建推进下的动态(下面授权)
            this.release(resp.data.data, this.orgList[inx].value)
          }
        } else { // 创建推进表
          code = 200
        }
        await this.getchild()
        await this.$emit('OrgPushInit')
        if (code === 200) {
          this.userSelect = false
          this.$swal({
            title: this.orgList.length ? '推送成功！' : '提交成功！',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
          })
        }
      },
      async release (item, groupId) {
        const filterFormData2 = {}
        filterFormData2.dynamicId = item.id
        filterFormData2.parentName = this.detailList.name
        filterFormData2.parentID = this.parentId
        filterFormData2.content = '待处理'
        filterFormData2.stageStatus = '待处理'
        filterFormData2.park = item.data.park
        filterFormData2.parkId = item.data.parkId
        filterFormData2.parkIds = item.data.parkIds
        filterFormData2.name = this.detailList.name + '--待处理'
        const resp = await this.$axios.post(
          `/forms/${this.dynamicformId}/reports3`,
          {
            data: filterFormData2,
            status: 0,
            weight: 10000,
            // 推送表id
            parentId: item.id,
          },
        )
        console.log('resp', resp)
        if (resp.data.code !== 200) {
          return this.$swal({
            title: '未知错误!',
            icon: 'error',
          })
        }
        // 授权这个推送
        this.putGroup(resp.data.data.id, groupId)
        // 授权这个项目
        this.putGroup(this.allparentId, groupId)
      },
      // 授权给市商务（投促）部门
      async putGroup (parentId, groupIds) {
        const res = await this.$axios.post(`/reports/${parentId}/access`, {
          grantId: groupIds,
          type: 'role',
          permission: 'read,write',
        })
        if (res.data.code !== 200) {
          return this.$swal({
            title: '授权失败!',
            icon: 'error',
          })
        }
        console.log(res)
      },
    },
  }
</script>
<style scoped lang='scss'>
::v-deep.v-dialog__content {
  justify-content: flex-end !important;
}
::v-deep .v-dialog {
  height: 86% !important;
}
::v-deep .fildName .v-text-field__details {
  display: none;
}
::v-deep .v-data-table__wrapper {
  max-height: 350px;
}
.tscNum ::v-deep .v-input__slot {
  border: 1px solid #d9d9d9;
  min-height: 33px;
}
</style>
